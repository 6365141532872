
    .likeMarket {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .likeMarket-header {

        }
        .content {
            flex: 1;
        }
    }
